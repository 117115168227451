import React from "react";
import {
  faHome,
  faEnvelope,
  faLandmark,
  faHandHoldingUsd,
  faUsers,
  faMoneyBillWave,
  faUser,
  faCoins,
  faCubes,
  faBezierCurve,
  faPercent,
  faPlus,
  faSignOutAlt,
  faUserSecret, 
  faGavel,
  faBell,
  faDownload,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { URL, WORKSPACETYPE } from "../../../core/constants";
import { TMP_CONTACT_ID } from "../../../core/apiconfig/constantApi";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { resetCache } from "../../../core/utils/cachManager";
import { useRegisterCompanyState } from "../../../context/registerCompany/RegisterCompanyState";

const RelatedMenus = ({ workspaceType, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { registerCompanyInfoAction } = useRegisterCompanyState();

  const isVermittler = workspaceType == WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED;
  const isContact = workspaceType == WORKSPACETYPE.CONTACT;
  const loggedinAsCustomer =
    localStorage.getItem(TMP_CONTACT_ID) != null &&
    localStorage.getItem(TMP_CONTACT_ID) != "";
  const isCompany =
    !isVermittler &&
    workspaceType != WORKSPACETYPE.CONTACT &&
    workspaceType != WORKSPACETYPE.UNDER_AGE;

  // Items for a customer
  const sidebarItemsCustomer = [
    {
      title: t("layout.dashboard"),
      url: URL.DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.inbox"),
      url: URL.INBOX,
      icon: faEnvelope,
    },
    {
      title: t("layout.storages"),
      url: URL.STORAGE,
      icon: faHandHoldingUsd,
    },
    {
      title: isCompany
        ? t("layout.action-codes-company")
        : t("layout.action-codes"),
      url: URL.ACTION_CODES,
      icon: faPercent,
    },
    /*
    // Feature currently disabled because users did not understand it
    {
      title: t("layout.contracts"),
      url: URL.CONTRACT,
      icon: faHandshake,
    },
    */
   
    // {
    //   title: "Vollmachten",
    //   url: URL.POWER_OF_ATTORNEY_BASE,
    //   icon: faAddressCard,
    // },
    // {
    //   title: "Edelmetall Kurse",
    //   url: URL.METALPRICE,
    //   icon: faEuroSign,
    // },
    // {
    //   title: "Berichte",
    //   url: URL.REPORT,
    //   icon: faChartBar,
    // },
    // {
    //   title: "News",
    //   url: URL.NEWS,
    //   icon: faGlobe,
    // },
    // {
    //   title: "Akademie",
    //   url: URL.ACADEMY,
    //   icon: faUserGraduate,
    // },
    {
      title: isCompany
        ? t("layout.bank-accounts-company")
        : t("layout.bank-accounts"),
      url: URL.BANKACCOUNT,
      icon: faLandmark,
    },
    {
      title: isCompany ? t("layout.profile-company") : t("layout.profile"),
      url: URL.PROFILE,
      icon: faUser,
    },
    {
      title: t("layout.events"),
      url: URL.EVENTS,
      icon: faBell,
    },
    {
      title: t("layout.downloads"),
      url: URL.DOWNLOADS,
      icon: faDownload,
    },
    // {
    //   title: "Precious Metal Prices",
    //   url: URL.PRICE_LIST_BASE,
    //   icon: faDollarSign,
    // },
  ];

  // Items for a partner (NOT just a company!)
  const sidebarItemsSeller = [
    {
      title: t("layout.dashboard-company"),
      url: URL.COPMANY_DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.inbox"),
      url: URL.INBOX,
      icon: faEnvelope,
    },
    {
      title: t("layout.my-storages"),
      url: URL.STORAGE,
      icon: faHandHoldingUsd,
    },
    {
      title: t("layout.action-codes-company"),
      url: URL.ACTION_CODES,
      icon: faPercent,
    },
    {
      title: t("layout.dashboard-partner"),
      url: URL.DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.customers"),
      url: URL.CUSTOMER,
      icon: faUsers,
    },
    {
      title: t("layout.customer-deposits"),
      url: URL.CUSTOMERDEPOSITS,
      icon: faCoins,
    },
    {
      title: t("layout.customer-storages"),
      url: URL.CUSTOMER_STORAGES,
      icon: faHandHoldingUsd,
    },
    {
      title: t("layout.customer-setup-fees"),
      url: URL.CUSTOMER_SETUP_FEES,
      icon: faFileInvoiceDollar,
    },
    // {
    //   title: "Mittel Verwendungs Kontrolle",
    //   url: URL.FUNDCONTROL,
    //   icon: faFileSignature,
    // },
    {
      title: t("layout.commissions"),
      url: URL.COMMISSION,
      icon: faMoneyBillWave,
    },
    {
      title: t("layout.competitions"),
      url: URL.COMPETITION,
      icon: faCubes,
    },
    {
      title: t("layout.my-structure"),
      url: URL.STRUCTURE,
      icon: faBezierCurve,
    },
    // {
    //   title: "Edelmetall Kurse",
    //   url: URL.METALPRICE,
    //   icon: faEuroSign,
    // },
    // {
    //   title: "Akademie",
    //   url: URL.ACADEMY,
    //   icon: faUserGraduate,
    // },
    // {
    //   title: "News",
    //   url: URL.NEWS,
    //   icon: faGlobe,
    // },
    {
      title: t("layout.bank-accounts"),
      url: URL.BANKACCOUNT,
      icon: faLandmark,
    },
    {
      title: t("layout.profile-partner"),
      url: URL.PROFILE,
      icon: faUser,
    },
    {
      title: t("layout.events"),
      url: URL.EVENTS,
      icon: faBell,
    },
    {
      title: t("layout.events-partner"),
      url: URL.EVENTS_PARTNER,
      icon: faBell,
    },
    {
      title: t("layout.downloads"),
      url: URL.DOWNLOADS,
      icon: faDownload,
    },
    {
      title: t("layout.downloads-partner"),
      url: URL.DOWNLOADS_PARTNER,
      icon: faDownload,
    },
    {
      title: t("layout.downloads-marketing"),
      url: URL.DOWNLOADS_MARKETING,
      icon: faDownload,
    },
  ];

  // Items when a partner views a customer's account
  const sidebarItemsCustomerFromVermittlerPerspective = [
    {
      title: t("layout.dashboard"),
      url: URL.DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.inbox"),
      url: URL.INBOX,
      icon: faEnvelope,
    },
    {
      title: t("layout.bank-accounts"),
      url: URL.BANKACCOUNT,
      icon: faLandmark,
    },
  ];

  // Items for everyone
  const sidebarItemsLegal = [
    {
      title: t("layout.datenschutz"),
      url: URL.PRIVACY,
      icon: faUserSecret,
    },
    {
      title: t("layout.impressum"),
      url: URL.IMPRINT,
      icon: faGavel,
    },
  ];

  return (
    <>
      {isContact && !loggedinAsCustomer && (
        <li key={"addWorkspaceMenu"} className="item">
          <MenuItem
            className={(navData) => (navData.isActive ? "active" : "none")}
            sx={{
              borderBottom: "solid 1px black",
              padding: "11.8px 19.2px",
              fontSize: 14,
              width: "100%",
            }}
            onClick={() => {
              registerCompanyInfoAction({
                company: null,
                step: 1,
                hideBackLink: false,
              });
              navigate(URL.WORKSPACEADD);
              if (onClick) { onClick(); }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <div style={{ paddingLeft: "5px" }}>
              {t("layout.add-workspace")}
            </div>
          </MenuItem>
        </li>
      )}
      {(isVermittler ? sidebarItemsSeller : (loggedinAsCustomer ? sidebarItemsCustomerFromVermittlerPerspective : sidebarItemsCustomer)).concat(sidebarItemsLegal).map(
        (item, index) => {
          return (
            <li key={index} className="item">
              <NavLink
                to={item.url}
                exact={"true"}
                className={(navData) => navData.isActive ? "active" : "none" }
                onClick={() => {
                  if (onClick) { onClick(); }
                }}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={item.icon} />
                </span>
                {item.title}
              </NavLink>
            </li>
          );
        }
      )}

      <li key={"logoutMenu"} className="item">
        <NavLink
          to={URL.LOGIN}
          exact={"true"}
          className={(navData) => (navData.isActive ? "active" : "none")}
          onClick={() => {
            resetCache();
            window.location.reload();
          }}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faSignOutAlt} />
          </span>
          {t("layout.logout")}
        </NavLink>
      </li>
    </>
  );
};

export default RelatedMenus;
