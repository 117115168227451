import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { dateString, localeFormat, timeString } from "../../../core/utils/convert";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faBuilding,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  MetalCell,
  ColorizeIncreaseAndDecrease,
  LoadingOverlay,
  Ribbon,
  BorderLinearProgress,
} from "../../common";
import { Link } from "react-router-dom";
import {
  URL,
  WORKSPACETYPE,
  ERROR_CONTACT_US,
  METAL_CHARS,
} from "../../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { customerLoyaltyData, globalsMetalPrices } from "../../../redux/slices/globals/globals.slice";
import {
  storageList,
  transactionList,
  vermittler,
  investList,
  globalData,
  resetError,
  resetActionStatus,
  companyData,
  invoiceBoxData,
} from "../../../redux/slices/clientDashboard/clientDashboard.slice";
import { useNavigate } from "react-router-dom";
import {
  getVermittlerAction,
  getTransactionListAction,
  getCustomerStorageListAction,
  getCompanyDataAction,
  getInvoiceTransferDataAction,
  getInvoiceTotalDataAction,
} from "../../../redux/slices/clientDashboard";
// chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  VERMITTLER_ID,
  WORKSPACE_NAME,
  TMP_CONTACT_ID,
} from "../../../core/apiconfig/constantApi";
//import CustomerActionCodeBox from "./customerActionCodeBox";
import { resetAll } from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { useTranslation } from "react-i18next";
import {
  BYPASS_ADOBE_SIGN,
  SHOW_MAINTENANCE_RIBBON,
  MAINTENANCE_TEXT,
} from "../../../core/apiconfig/environment";
import { getCustomerLoyaltyDataAction, getMetalPriceAction } from "../../../redux/slices/globals";
import PreciousMetalsChart from "../../common/PreciousMetalsChart/PreciousMetalsChart";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomerDashboard = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();

  const companyInfo = useSelector(companyData);
  const dashboardGlobalData = useSelector(globalData);
  const storageListFields = useSelector(storageList); // #storageList
  const transactionListFields = useSelector(transactionList);
  const vermittlerFields = useSelector(vermittler);
  const investListFields = useSelector(investList);
  const metalPrices = useSelector(globalsMetalPrices);
  const customerLoyaltyInfo = useSelector(customerLoyaltyData);
  const invoiceBox = useSelector(invoiceBoxData);
  const [companyInfoState, setCompanyInfoState] = useState(false);
  const [oldestPriceDate, setOldestPriceDate] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const loggedinAsCustomer =
    localStorage.getItem(TMP_CONTACT_ID) !== null &&
    localStorage.getItem(TMP_CONTACT_ID) !== "";

  useEffect(() => {
    setLoading(true);

    if (
      localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.CONTACT &&
      localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.UNDER_AGE
    ) {
      dispatch(getCompanyDataAction({ id: localStorage.getItem(WORKSPACE_ID) }));
    }

    dispatch(getInvoiceTransferDataAction());
    dispatch(getInvoiceTotalDataAction());

    dispatch(getMetalPriceAction({ productName: METAL_CHARS.GOLD }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.SILVER }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.PLATIN }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.PALLADIUM }));

    dispatch(getVermittlerAction(localStorage.getItem(VERMITTLER_ID)));
    dispatch(
      getCustomerStorageListAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceType: localStorage.getItem(WORKSPACE_TYPE),
        fetchSubStorages: false,
        fetchPrices: true,
      })
    );
    dispatch(
      getTransactionListAction()
    );

    dispatch(
      getCustomerLoyaltyDataAction({
        customerId: localStorage.getItem(WORKSPACE_ID),
        isContact:
          localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT ||
          localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE,
      })
    );
  }, [dispatch, loggedinAsCustomer]);

  useEffect(() => {
    if (dashboardGlobalData.error != null) {
      enqueueSnackbar(dashboardGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [dashboardGlobalData.error, dispatch]);

  useEffect(() => {
    if (dashboardGlobalData.actionStatus) {
      if (dashboardGlobalData.actionType == "getCompanyData") {
        setCompanyInfoState(companyInfo);
        if (companyInfo.canBecomeSeller)
          localStorage.setItem(
            WORKSPACE_TYPE,
            WORKSPACETYPE.SIMPLE_ACCOUNT_VERIFIED
          );
        dispatch(resetActionStatus());
      } else if (dashboardGlobalData.actionType == "storageDetailList") {
        setLoading(false);
        dispatch(resetActionStatus());
      }
    }
  }, [dashboardGlobalData.actionStatus, dispatch]);

  // Get the oldest price date to show it because the dates should be the same, there is only one place to show the date and it is safer to show the "worst" one
  useEffect(() => {
    let oldest = null;
    for (const date of [metalPrices.goldPrice?.priceDate, metalPrices.silverPrice?.priceDate, metalPrices.platinPrice?.priceDate, metalPrices.palladiumPrice?.priceDate]) {
      if (!oldest || new Date(date) < new Date(oldest)) { oldest = date; } // Update oldest date
    }
    setOldestPriceDate(oldest);
  }, [metalPrices]);

  return (
    <LoadingOverlay show={loading}>
      {SHOW_MAINTENANCE_RIBBON && (
        <Ribbon
          height={110}
          description={
            <div>
              Liebe Kunden,
              <br></br> {MAINTENANCE_TEXT} <br></br>{" "}
              Vielen Dank für Ihr Verständnis!
            </div>
          }
        ></Ribbon>
      )}

      {companyInfoState.canBecomeSeller && (
        <Ribbon
          buttonText={t("customer-dashboard.become-seller")}
          description={t("customer-dashboard.become-seller-question")}
          onClick={() => {
            dispatch(resetAll());
            navigate(URL.BECOMEASELLER);
          }}
        ></Ribbon>
      )}

      {localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.SELLER_ACCOUNT_UNVERIFIED &&
        companyInfoState.signedContract && (
          <Ribbon
            description={t("customer-dashboard.become-seller-in-process")}
          ></Ribbon>
        )}

      {!BYPASS_ADOBE_SIGN &&
        localStorage.getItem(WORKSPACE_TYPE) ===  WORKSPACETYPE.SELLER_ACCOUNT_UNVERIFIED &&
        !companyInfoState.signedContract && (
          <Ribbon
            buttonText={t("buttons.sign-now")}
            description={t("customer-dashboard.become-seller-unsigned")}
            onClick={() => navigate(URL.SIGN_SELLER_CONTRACTS)}
          ></Ribbon>
        )}

      {storageListFields.length === 0 && !loading && ( // Note to create storage, not while loading because it is too distracting in the background
        <Ribbon
          buttonText={t("customer-dashboard.add-storage")}
          description={t("customer-dashboard.no-storage-created-yet")}
          onClick={() => {
            navigate(URL.ADDSTORAGE);
          }}
          disabledReason={loggedinAsCustomer ? t("customer-dashboard.no-storage-created-yet-disabled-for-partner") : undefined}
          highlightButton={true}
        ></Ribbon>
      )}

      <Box
        maxWidth={"100%"}
        sx={isDesktop ? { paddingTop: "40px", paddingLeft: "72px", paddingRight: "72px", paddingBottom: "20px" } : { paddingTop: "40px", paddingLeft: "12px", paddingRight: "12px", paddingBottom: "20px" }}
      >
        <Grid mt="10px" spacing={8} container>
          <Grid item xs={12}>
            <Typography variant="h5">
              {localStorage.getItem(WORKSPACE_NAME)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Typography variant="subtitle1" component="h6">{ t("customer-dashboard.invest-chart") }</Typography>
              <Box mt={6}>
                <PreciousMetalsChart prices={investListFields} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6" sx={{ lineHeight: 1 }}>
                  {t("customer-dashboard.invest")}<br />
                  <span style={{ fontSize: "10px", color: "gray" }}>
                    {oldestPriceDate ? <>
                      {`${t("general.date-disclaimer.as-of")}: ${dateString(oldestPriceDate)} ${t("general.date-disclaimer.at")} ${timeString(oldestPriceDate)} ${t("general.date-disclaimer.hour")}`}
                    </> : <>&nbsp;</>}
                  </span>
                </Typography>
                <Typography
                  sx={{ color: "#1976D2", fontWaight: 700 }}
                  variant="subtitle1"
                  component="h6"
                >
                  {localeFormat(
                    parseFloat(investListFields?.AU?.price) +
                    parseFloat(investListFields?.AG?.price) +
                    parseFloat(investListFields?.PT?.price) +
                    parseFloat(investListFields?.PL?.price),
                    { minimumFractionDigits: 2, currency: "EUR" }
                  )}
                </Typography>
              </Box>
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell subtext={metalPrices.goldPrice ? (localeFormat(metalPrices.goldPrice?.amount, { minimumFractionDigits: 4, currency: "EUR" }) + "/g") : "…"} metal="Gold"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {localeFormat(investListFields?.AU?.price, { minimumFractionDigits: 2, currency: "EUR" })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell subtext={metalPrices.silverPrice ? (localeFormat(metalPrices.silverPrice?.amount, { minimumFractionDigits: 4, currency: "EUR" }) + "/g") : "…"} metal="Silver"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {localeFormat(investListFields?.AG?.price, { minimumFractionDigits: 2, currency: "EUR" })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell subtext={metalPrices.platinPrice ? (localeFormat(metalPrices.platinPrice?.amount, { minimumFractionDigits: 4, currency: "EUR" }) + "/g") : "…"} metal="Platin"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {localeFormat(investListFields?.PT?.price, { minimumFractionDigits: 2, currency: "EUR" })}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell subtext={metalPrices.palladiumPrice ? (localeFormat(metalPrices.palladiumPrice?.amount, { minimumFractionDigits: 4, currency: "EUR" }) + "/g") : "…"} metal="Palladium"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {localeFormat(investListFields?.PL?.price, { minimumFractionDigits: 2, currency: "EUR" })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Paper
              sx={{
                p: "16px",
                height: 1,
                bgcolor: "#E3F2FD",
                color: "#0D47A1",
              }}
              variant="outlined"
            >
              <Typography
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                variant="subtitle1"
                component="h6"
              >
                {t("customer-dashboard.my-ratior")}
              </Typography>
              {localStorage.getItem(VERMITTLER_ID) && (
                <>
                  <Typography my="20px" variant="h6" component="h6">
                    {vermittlerFields.name}
                  </Typography>
                  <Box
                    sx={{
                      "& p": {
                        mb: "10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                      variant="caption"
                      component="p"
                    >
                      <FontAwesomeIcon size="lg" icon={faPhone} /> &nbsp;
                      <a href={"tel:" + vermittlerFields.phoneNumber}>
                        {vermittlerFields.phoneNumber}
                      </a>
                    </Typography>
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                      variant="caption"
                      component="p"
                    >
                      <FontAwesomeIcon size="lg" icon={faEnvelope} /> &nbsp;
                      <a
                        target="_blank" rel="noopener noreferrer"
                        href={"mailto:" + vermittlerFields.email}
                      >
                        {vermittlerFields.email}
                      </a>
                    </Typography>
                    <Typography variant="caption" component="p">
                      <FontAwesomeIcon size="lg" icon={faBuilding} /> &nbsp;
                      <a
                        target="_blank" rel="noopener noreferrer"
                        href={
                          "https://www.google.com/maps/search/?api=1&query=" +
                          vermittlerFields?.latitude +
                          "," +
                          vermittlerFields?.longitude
                        }
                      >
                        {vermittlerFields.addressLine1}
                        <br></br>
                        {vermittlerFields.postalcode}
                        &nbsp;&nbsp;
                        {vermittlerFields.city}
                        <br></br>
                        {vermittlerFields.landTitle}
                      </a>
                    </Typography>
                  </Box>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="h6">
                  {t("customer-dashboard.sales-fee")}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "5px",
                    }}
                  >
                    {t("action-code.loyalty-months") +
                      ": " +
                      customerLoyaltyInfo?.loyaltyMonthes}
                  </Typography>
                </Box>

                <BorderLinearProgress
                  variant="determinate"
                  value={customerLoyaltyInfo?.loyaltyProgressPercent ?? 0}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.6)",
                    paddingTop: "5px",
                  }}
                >
                  {customerLoyaltyInfo?.hasLoyalty
                    ? `${customerLoyaltyInfo?.loyaltyName}`
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.6)",
                    paddingTop: "5px",
                  }}
                >
                  {customerLoyaltyInfo?.hasLoyalty
                    ? `(${t("action-code.fee-percent")} : ${
                        customerLoyaltyInfo?.feePercent_formatted
                      }%)`
                    : ""}
                </Typography>

                {customerLoyaltyInfo?.monthesToNextLevel >= 0 && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {`${t("customer-dashboard.month-to-next-1")} ${
                      customerLoyaltyInfo?.monthesToNextLevel
                    } ${t("customer-dashboard.month-to-next-2")}`}
                  </Typography>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="h6">
                    {t("customer-dashboard.transfer-title")}
                  </Typography>
                </Grid>

                {customerLoyaltyInfo?.ratiorRating > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} sm={8}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.6)",
                            paddingTop: "5px",
                            textAlign: !isDesktop ? "left" : "inherit",
                          }}
                        >
                          {t("customer-dashboard.transfer-amount1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.6)",
                            paddingTop: "5px",
                            textAlign: !isDesktop ? "left" : "right",
                          }}
                        >
                          {` ${localeFormat(customerLoyaltyInfo?.ratiorRating, { minimumFractionDigits: 2, currency: "EUR" })}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: !isDesktop ? "left" : "inherit",
                    }}
                  >
                    {t("customer-dashboard.transfer-amount2")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: !isDesktop ? "left" : "right",
                    }}
                  >
                    {` ${localeFormat(customerLoyaltyInfo?.investedAmount, { minimumFractionDigits: 2, currency: "EUR" })}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: !isDesktop ? "left" : "inherit",
                    }}
                  >
                    {t("customer-dashboard.transfer-sum")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: !isDesktop ? "left" : "right",
                    }}
                  >
                    {` ${localeFormat(customerLoyaltyInfo?.sumAmount || 0, { minimumFractionDigits: 2, currency: "EUR" })}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: !isDesktop ? "left" : "inherit",
                    }}
                  >
                    {t("customer-dashboard.transfer-current-level") + " " + (customerLoyaltyInfo?.feeLevel ?? "…")}
                  </Typography>
                </Grid>
                {customerLoyaltyInfo.typeIsLevels &&
                  !customerLoyaltyInfo.currentLevelIsLast && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.6)",
                          paddingTop: "5px",
                          textAlign: !isDesktop ? "left" : "inherit",
                        }}
                      >
                        {`${t("customer-dashboard.transfer-to")} ${localeFormat(
                          customerLoyaltyInfo?.feeAmountToNextLevel || 0, { minimumFractionDigits: 2, currency: "EUR" }
                        )}${t("customer-dashboard.transfer-level")} ${
                          customerLoyaltyInfo?.nextLevel || "…"
                        }`}
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px" }}>
            <Paper
              sx={{ p: "16px", minHeight: "150px" }}
              variant="outlined"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="h6">
                    {t("customer-dashboard.invoice-title")}
                  </Typography>
                </Grid>
                {/* Invoice Details Section */}
                {[{
                  label: t("customer-dashboard.invoice-amount1"),
                  value: localeFormat(invoiceBox?.totalSum ?? 0, { minimumFractionDigits: 2, currency: "EUR" }),
                }, {
                  label: t("customer-dashboard.invoice-amount2"),
                  value: localeFormat(invoiceBox?.transferSum ?? 0, { minimumFractionDigits: 2, currency: "EUR" }),
                }, {
                  label: t("customer-dashboard.invoice-sum"),
                  value: localeFormat((invoiceBox?.transferSum ?? 0) + (invoiceBox?.totalSum ?? 0), { minimumFractionDigits: 2, currency: "EUR" }),
                }].map(({ label, value }, index) => (
                  <Grid container key={index} spacing={1}>
                    <Grid item xs={12} sm={isDesktop ? 8 : 12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.6)",
                          paddingTop: "5px",
                        }}
                      >
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={isDesktop ? 4 : 12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.6)",
                          paddingTop: "5px",
                          textAlign: isDesktop ? "right" : "left",
                        }}
                      >
                        {value}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Paper>
            <Paper
              sx={{
                p: "16px",
                "& a": {
                  textDecoration: "none",
                },
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("customer-dashboard.my-storages")}
                </Typography>
                {!loggedinAsCustomer && (
                  <Typography
                    onClick={() => {
                      if (!storageListFields || storageListFields.length === 0) { navigate(URL.ADDSTORAGE); return; }
                      navigate(URL.STORAGE);
                    }}
                    sx={{
                      color: "rgba(21, 101, 192, 1)",
                      fontWaight: 700,
                      cursor: "pointer",
                    }}
                    variant="subtitle2"
                    component="h6"
                  >
                    {!storageListFields || storageListFields.length == 0
                      ? `+ ${t("customer-dashboard.add-storage")} `
                      : t("customer-dashboard.show-my-storages")}
                  </Typography>
                )}
              </Box>

              {!storageListFields ||
                (storageListFields.length == 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100% - 28px)",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgba(0, 0, 0, 0.38)", marginTop: "20px" }}
                      variant="caption"
                    >
                      {t("customer-dashboard.no-storages")}
                    </Typography>
                  </Box>
                ))}
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  {storageListFields
                    .map(
                      ({
                        msdyn_warehouseid,
                        metalName,
                        msdyn_name,
                        storageDescription,
                        arades_verfuegbare_menge,
                      }) => (
                        <TableRow
                          key={msdyn_warehouseid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            <Box sx={{ display: "flex" }}>
                              <MetalCell metal={metalName}></MetalCell>
                              <Typography mt="6px" variant="caption" component="p">
                                &nbsp;&nbsp;{`(${msdyn_name})`}
                              </Typography>
                            </Box>
                            {storageDescription && <>
                              <Typography mt="6px" variant="caption" component="p">
                                {storageDescription}
                              </Typography>
                            </>}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              whiteSpace: "nowrap",
                              color: "rgba(33, 150, 243, 1)",
                            }}
                          >
                            {!loggedinAsCustomer && (
                              <Link to={`${URL.STORAGE}/${msdyn_warehouseid}`}>
                                {localeFormat(arades_verfuegbare_menge, { minimumFractionDigits: 4 })}&nbsp; Gramm &nbsp;
                                <FontAwesomeIcon
                                  color="rgba(0, 0, 0, 0.6)"
                                  size="lg"
                                  icon={faAngleRight}
                                />
                              </Link>
                            )}
                            {loggedinAsCustomer && (
                              <Typography mt="6px" variant="caption" component="p">
                                &nbsp;&nbsp;{localeFormat(arades_verfuegbare_menge, { minimumFractionDigits: 4 })}&nbsp; Gramm &nbsp;
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{ p: "16px", height: 1, minHeight: "420px" }}
              mb="10"
              variant="outlined"
            >
              <Typography variant="subtitle1" component="h6">
                {t("customer-dashboard.my-transactions")}
              </Typography>
              {!transactionListFields ||
                (transactionListFields.length == 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100% - 28px)",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgba(0, 0, 0, 0.38)", marginTop: "20px" }}
                      variant="caption"
                    >
                      {t("customer-dashboard.no-transactions")}
                    </Typography>
                  </Box>
                ))}
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  {transactionListFields.map(
                    (
                      {
                        storageName,
                        metalName,
                        createdon_formatted,
                        amount_formatted,
                        direction,
                      },
                      index
                    ) => (
                      <TableRow
                        key={metalName + index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: 500,
                          }}
                        >
                          <Box
                            align="left"
                            sx={{
                              fontWeight: 500,
                              display: "flex",
                            }}
                          >
                            <MetalCell metal={metalName}></MetalCell>
                            <Typography
                              mt="6px"
                              variant="caption"
                              component="p"
                            >
                              &nbsp;&nbsp;{`(${storageName})`}
                            </Typography>
                          </Box>
                          <Typography mt="6px" variant="caption" component="p">
                            {createdon_formatted}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            whiteSpace: "nowrap",
                            verticalAlign: "top",
                          }}
                        >
                          <ColorizeIncreaseAndDecrease
                            status={direction == "up"}
                          >
                            {amount_formatted}
                          </ColorizeIncreaseAndDecrease>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <CustomerActionCodeBox></CustomerActionCodeBox>
          </Grid> */}
        </Grid>
      </Box>
    </LoadingOverlay>
  );
};

export default CustomerDashboard;
