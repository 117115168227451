import React, { useRef, useEffect, useState } from "react";
import { Box } from "@mui/material";

// Menu page that shows an iframe
const IframePage = ({url}) => {
    return <Box sx={{ width: "100%", minHeight: "100%", overflow: "hidden" }}>
        <iframe
            src={url + "?dashboardView"} /* dashboardView -> Trigger WPCode snippet in iframe code */
            style={{ border: "none", width: "100%", height: "100%" }}
        ></iframe>
    </Box>;
};

export default IframePage;
