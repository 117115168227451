import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCustomerSetupFeeListFunc = async ({ vermittlerId }, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/quotes/details/setup-fees/vermittler/${vermittlerId}/`, config);
  if (result?.status != 200) { throw Error("Einrichtungsgebühren konnte nicht geladen werden"); }
  const setupFeeEntries = result.data.map((entry) => {
    // Example for entry: {
    //   "quoteid": "ecd7cc39-c8ab-ee11-a568-6045bd2b779a",
    //   "arades_angebot_annahme": 100000000, "arades_typ": 100000002,
    //   "arades_lagerplatzstammnummer": "G-686066",
    //   "arades_einzelpreis": 700, "totalamount": 0,
    //   "arades_datum_geldeingang": "2024-01-05T12:45:00Z",
    //   "promo.arades_prozent": 100, "promo.arades_name": "X1070S", "promo.arades_anlas": "0€ Einrichtungsgebühr 100% Rabatt",
    //   "c.contactid": "eb00934d-c6ab-ee11-a568-002248f33e54", "c.fullname": "Max Mustermann",
    //   OR
    //   "a.accountid": "e7d7cc39-c8ab-ee11-a568-6045bd2b779a", "a.name": "Muster GmbH",
    //   ...
    // }
    return {
      ...entry,
      "customerType": entry["c.contactid"] ? "contact" : "account",
      "customerId": entry["c.contactid"] || entry["a.accountid"],
      "customerName": entry["c.fullname"] || entry["a.name"],
      "promoCode": entry["promo.arades_name"],
      "promoName": entry["promo.arades_anlas"],
      "promoPercent": entry["promo.arades_prozent"],
    };
  });
  return setupFeeEntries;
};
export default getCustomerSetupFeeListFunc;
