import { createAsyncThunk } from "@reduxjs/toolkit";
import { WORKSPACE_ID, TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL, BASE_URL_UI } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import axios from "axios";
import i18n from "i18next";

const postCustomerInfoAction = createAsyncThunk(
  "customerList/postCustomerInfo",
  async (contactInfo, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let registerObj = {
      email: contactInfo.emailaddress1,
      birthdate: longDateTo10(contactInfo.birthdate),
      firstname: contactInfo.firstname,
      lastname: contactInfo.lastname,
      title: contactInfo.arades_titel,
      gender: contactInfo.arades_anrede,
      promotionCode: contactInfo.actionCode ? contactInfo.actionCode : null,
      dataPolicy: true,
      vermittlerId: localStorage.getItem(WORKSPACE_ID),
      redirectUrl: BASE_URL_UI + "definepass",
    };

    if (registerObj.title == "") registerObj.title = null;
    const registerResult = await axios.post(`${BASE_URL}auth/register`, registerObj, config);
    if (registerResult?.data?.status === 409) { // Duplicate email?
      throw Error(i18n.t("messages.duplicate-email-when-adding-customer"));
    }
    if (registerResult?.data?.status !== 201 && registerResult?.data?.status !== 200) {
      throw Error(registerResult?.data?.statusText);
    } else return true;
  }
);

export default postCustomerInfoAction;
