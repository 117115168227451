import React, { useState, useEffect } from "react";

import { Box, Grid, Button, Typography } from "@mui/material";
import { FormStepperStructure, LoadingOverlay } from "../common";
import armIcon from "../../assets/images/arm.svg";
import warningIcon from "../../assets/images/warning.svg";
import { useSnackbar } from "notistack";
import { getMetalPriceAction } from "../../redux/slices/globals";
import { globalsMetalPrices } from "../../redux/slices/globals/globals.slice";
import { ERROR_CONTACT_US, METAL_CHARS } from "../../core/constants";
import { useTranslation } from "react-i18next";
import {
  globalData,
  exchangeInfo,
  resetActionStatus,
  resetError,
} from "../../redux/slices/exchange/exchange.slice";
import { useDispatch, useSelector } from "react-redux";
import { localeFormat } from "../../core/utils/convert";

function ProfitCalculator({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [warning, setWarning] = useState(true);
  const [loading, setLoading] = useState(false);

  const exchangeGlobalData = useSelector(globalData);
  const exchangeInfoData = useSelector(exchangeInfo);
  const metalPrices = useSelector(globalsMetalPrices);

  useEffect(() => {
    setLoading(true);
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.GOLD }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.SILVER }));
  }, []);

  useEffect(() => {
    if (exchangeGlobalData.actionStatus) {
      dispatch(resetActionStatus());
    }
  }, [exchangeGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(exchangeGlobalData.loading);
  }, [exchangeGlobalData.loading]);

  useEffect(() => {
    if (
      metalPrices.goldPrice?.amount &&
      metalPrices.silverPrice?.amount &&
      exchangeInfoData.from
    ) {
      if (exchangeInfoData.from === "Silver") {
        if (
          metalPrices.goldPrice?.amount / metalPrices.silverPrice?.amount >
          40
        ) {
          setWarning(true);
        } else {
          setWarning(false);
        }
      } else {
        if (
          metalPrices.goldPrice?.amount / metalPrices.silverPrice?.amount >
          40
        ) {
          setWarning(false);
        } else {
          setWarning(true);
        }
      }
    }
  }, [
    metalPrices.goldPrice?.amount,
    metalPrices.silverPrice?.amount,
    exchangeInfoData.from,
  ]);

  useEffect(() => {
    if (exchangeGlobalData.error != null) {
      enqueueSnackbar(exchangeGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [exchangeGlobalData.error]);

  const onSubmit = (data) => {
    handleCurrentStep("next");
  };

  return (
    <LoadingOverlay show={loading}>
      <FormStepperStructure.Container minHeight="calc(100vh - 300px)">
        <Box
          sx={{
            maxWidth: "600px",
            margin: "80px auto",
          }}
        >
          <Grid spacing={8} direction="column" container>
            {warning ? (
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    mb: "15px",
                  }}
                >
                  <img width="35px" src={warningIcon} />
                  <Typography
                    sx={{
                      mx: "15px",
                      fontWeight: 500,
                    }}
                    variant="h5"
                  >
                    {t("exchange.wait")}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {t("exchange.profit-description")}
                </Typography>
              </Grid>
            ) : (
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    mb: "15px",
                  }}
                >
                  <img width="35px" src={armIcon} />
                  <Typography
                    sx={{
                      mx: "15px",
                      fontWeight: 500,
                    }}
                    variant="h5"
                  >
                    Let’s go for it
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {t("exchange.market-analysis")}
                </Typography>
              </Grid>
            )}
            <Grid item container>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontWeight: 500 }} variant="h6">
                  {t("exchange.gold")}
                </Typography>
                <Typography>
                  {metalPrices.goldPrice && localeFormat(metalPrices.goldPrice.amount, { minimumFractionDigits: 4, currency: "EUR" })}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontWeight: 500 }} variant="h6">
                  {t("exchange.silver")}
                </Typography>
                <Typography>
                  {metalPrices.silverPrice && localeFormat(metalPrices.silverPrice.amount, { minimumFractionDigits: 4, currency: "EUR" })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            onClick={onSubmit}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </FormStepperStructure.Container>
    </LoadingOverlay>
  );
}

export default ProfitCalculator;
