import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomerDetailedListAction,
  postCustomerInfoAction,
  getActionCodeCustomerDataAction,
  postCustomerActionCodeAction,
} from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  detailedList: [],
  actionCodeList: [],
};
export const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //get Customer Detailed List Action
    [getCustomerDetailedListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerDetailedListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.detailedList = action.payload;
      state.globalData.actionType = "getDetailedList";
    },
    [getCustomerDetailedListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getDetailedList";
      state.globalData.error = action.error.message;
    },

    //post new customer
    [postCustomerInfoAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postCustomerInfoAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postCustomerInfo";
      state.globalData.actionStatus = true;
    },
    [postCustomerInfoAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postCustomerInfo";
      state.globalData.error = action.error.message;
    },

    //post customer_actioncode
    [postCustomerActionCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postCustomerActionCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postCustomerActionCode";
      state.globalData.actionStatus = true;
    },
    [postCustomerActionCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postCustomerActionCode";
      state.globalData.error = action.error.message;
    },

    //ActionCode Data
    [getActionCodeCustomerDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getActionCodeCustomerDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getCustomerActionCodes";
      state.actionCodeList = action.payload;
      state.globalData.actionStatus = true;
    },
    [getActionCodeCustomerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const fullData = (state) => state.customerList;
export const globalData = (state) => state.customerList.globalData;

export const customerDetailedList = (state) => state.customerList.detailedList;
export const customerActionCodeList = (state) => state.customerList.actionCodeList;

export const { resetError, resetActionStatus, resetAll } = customerListSlice.actions;
export default customerListSlice.reducer;
