import React, { useEffect, useState, useCallback } from "react";
import { IconButton, Paper, InputBase } from "@mui/material";
import MdSearch from "@mui/icons-material/Search";

function Search({ onSearch, placeholder }) {
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedTerm(term);
    }, 700);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [term]);

  useEffect(() => {
    onSearch(debouncedTerm);
  }, [debouncedTerm, onSearch]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleChange = useCallback((event) => {
    setTerm(event.target.value);
  }, []);

  return (
    <>
      <Paper
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: "1px 4px",
          display: "flex",
          alignItems: "center",
          width: "400px",
          maxWidth: "100%",
          boxShadow: "none",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <InputBase
          sx={{ flex: 1 }}
          placeholder={placeholder}
          value={term}
          onChange={handleChange}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <MdSearch />
        </IconButton>
      </Paper>
    </>
  );
}

export default Search;
