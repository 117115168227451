import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getMetalPriceFunc } from "../";
import {
  dateString,
  zeroPad,
  localeFormat,
} from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import {
  WORKSPACETYPE,
  METAL_CHARS,
} from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCustomerStorages = async (
  workspaceType,
  customerId,
  prices,
  fetchSubStorages
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result;
  if (workspaceType === WORKSPACETYPE.CONTACT) {
    result = await axios.get(`${BASE_URL}data/warehouses/aggregated/contact/${customerId}/?fetchSubStorages=${fetchSubStorages ? "true" : "false"}`, config);
  } else if (workspaceType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/warehouses/aggregated/contact/${customerId}/child/?fetchSubStorages=${fetchSubStorages ? "true" : "false"}`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/warehouses/aggregated/account/${customerId}/?fetchSubStorages=${fetchSubStorages ? "true" : "false"}`, config);
  }

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = result.data.data.value.map((item) => {
    let metalName =
      item["arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"];

    let createDate =
      zeroPad(item.createyear, 4) +
      "-" +
      zeroPad(item.createmonth, 2) +
      "-" +
      zeroPad(item.createday, 0);

    let unitPrice =
      metalName.toLowerCase().indexOf("gold") >= 0
        ? prices.gold
        : metalName.toLowerCase().indexOf("silber") >= 0
        ? prices.silver
        : metalName.toLowerCase().indexOf("platin") >= 0
        ? prices.platin
        : metalName.toLowerCase().indexOf("palladium") >= 0
        ? prices.palladium
        : 0;
    let tmp = {
      msdyn_warehouseid: item["msdyn_warehouseid"], // e.g. 04235b5d-3079-ef11-ac20-00224813148d
      msdyn_name: item["msdyn_name"], // e.g. A-286584-0-AG-02
      storageDescription: item["msdyn_description"],
      arades_uebergeordneter_lagerplatz: item["arades_uebergeordneter_lagerplatz"], // e.g. fb74e0e8-2071-ef11-a670-002248cabe61
      statuscode: item["statuscode"],
      statecode: item["statecode"],
      arades_lagerplatz_typ: item["arades_lagerplatz_typ"],
      statusText: storageStatus(item.statuscode),
      arades_menge: item["arades_menge"] ?? 0,
      arades_verzicht_auf_widerruf: item["arades_verzicht_auf_widerruf"],
      arades_einrichtungsgebuehr_beglichen: item["arades_einrichtungsgebuehr_beglichen"],
      arades_vertrag: item["arades_vertrag"],
      createdon: createDate,
      createdon_formatted: dateString(createDate),
      metalName: metalName,
      unitPrice: unitPrice,
      totalPrice: unitPrice * (item["arades_verfuegbare_menge"] ?? 0),
      subStorageCount: item.subStorageCount,
      subStorageList: [],
    };

    let isPending = storageIsPending(tmp);
    tmp.isPending = isPending;
    tmp.arades_verfuegbare_menge = !isPending
      ? item["arades_verfuegbare_menge"] ?? 0
      : 0;
    tmp.arades_menge = !isPending ? (item["arades_menge"] ?? 0) : 0;
    tmp.arades_verfuegbare_menge_formatted = !isPending
      ? localeFormat(item["arades_verfuegbare_menge"] ?? 0, { minimumFractionDigits: 4 }) + " g"
      : "Pending";
    tmp.arades_menge_formatted = !isPending
      ? localeFormat(item["arades_menge"] ?? 0, { minimumFractionDigits: 4 }) + " g"
      : "Pending";
    tmp.price = !isPending
      ? unitPrice * (item["arades_verfuegbare_menge"] ?? 0)
      : 0;
    tmp.price_formatted = localeFormat(tmp.price, { minimumFractionDigits: 2, currency: "EUR" });
    tmp.baseNumber = item["msdyn_name"].split("-")[0] + "-" + item["msdyn_name"].split("-")[1]; // Stammnummer of the series of storage spaces e.g. "A-286584"
    return tmp;
  });
  // Group by baseNumber, then sort by newest createdon date within each group
  // 1) Build a map of baseNumber => most recent createdon (Date)
  const mostRecentDateByBaseNumber = new Map();
  for (const item of list) {
    const existingDate = mostRecentDateByBaseNumber.get(item.baseNumber);
    if (!existingDate || new Date(item.createdon) > existingDate) { // Update the map only if we found a newer date
      mostRecentDateByBaseNumber.set(item.baseNumber, new Date(item.createdon));
    }
  }
  // 2) Sort the list by baseNumber and (secondary) most recent date within its group
  list.sort((a, b) => {
    if (a.baseNumber === b.baseNumber) { return 0; } // If both items have the same baseNumber, don't change their relative order
    const maxDateA = mostRecentDateByBaseNumber.get(a.baseNumber); // Newest date of the group that 'a' belongs to
    const maxDateB = mostRecentDateByBaseNumber.get(b.baseNumber); // Newest date of the group that 'b' belongs to
    const diff = maxDateB - maxDateA; // Sort descending by the group's most recent date
    return diff === 0 ? 0 : diff;
  });
  return list;
};

const getCustomerStorageListFunc = async (filter, { getState }) => { // Data to #storageList
  const { workspaceId, workspaceType, fetchSubStorages, fetchPrices } = filter;

  let prices = { gold: 0, silver: 0, platin: 0, palladium: 0 };

  if (fetchPrices) {
    const [goldPrice, silverPrice, platinPrice, palladiumPrice] = await Promise.all([ // Fetch all metal prices in parallel
      getMetalPriceFunc({ productName: METAL_CHARS.GOLD },      { getState }),
      getMetalPriceFunc({ productName: METAL_CHARS.SILVER },    { getState }),
      getMetalPriceFunc({ productName: METAL_CHARS.PLATIN },    { getState }),
      getMetalPriceFunc({ productName: METAL_CHARS.PALLADIUM }, { getState })
    ]);
    prices.gold      = goldPrice.amount;
    prices.silver    = silverPrice.amount;
    prices.platin    = platinPrice.amount;
    prices.palladium = palladiumPrice.amount;
  }

  const storages = await getCustomerStorages(
    workspaceType,
    workspaceId,
    prices,
    fetchSubStorages
  );

  return storages.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getCustomerStorageListFunc;
