import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../common";
import { useSnackbar } from "notistack";
import LoadingOverlay from "../common/LoadingOverlay/LoadingOverlay";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { ERROR_CONTACT_US, QUOTE_STATUS } from "../../core/constants";
import { resetActionStatus, resetAll, resetError } from "../../redux/slices/customerSetupFeeList/customerSetupFeeList.slice";
import { getCustomerSetupFeeListAction } from "../../redux/slices/customerSetupFeeList";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";
import { dateString, localeFormat } from "../../core/utils/convert";
import CheckIcon from "@mui/icons-material/Check";
import ClockIcon from "@mui/icons-material/Schedule";

const CustomerSetupFeeList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();

  const customerSetupFeeListData = useSelector((state) => state.customerSetupFeeList.setupFeeList);
  const customerSetupFeeListGlobalData = useSelector((state) => state.customerSetupFeeList.globalData);

  const [setupFeeListFullPrice, setSetupFeeListFullPrice] = useState([]); // List of people that did already pay the full price
  useEffect(() => {
    if (!customerSetupFeeListData) { return; }
    setSetupFeeListFullPrice(customerSetupFeeListData
      .filter((item) => item.totalamount === item.arades_einzelpreis && item.totalamount > 0 && item.arades_datum_geldeingang) // > 0 -> Exclude free test setups
      .map((item, index) => ({ ...item, numbering: index + 1 }))
    );
  }, [customerSetupFeeListData]);
  const [onlyShowFullPrice, setOnlyShowFullPrice] = useState(false); // Checkbox filter state for only full-price

  const [dataTableColumns, setDataTableColumns] = useState([]);
  useEffect(() => { // Set the columns for the data table depending on whether the filter for only full-price is active
      setDataTableColumns((onlyShowFullPrice ? [
        {
          name: "numbering",
          caption: "#",
          width: "50px",
        },
      ] : []).concat([
        {
          name: "customerName",
          caption: t("customer-setup-fees-list.columns.customer-name"),
        },
        {
          name: "arades_lagerplatzstammnummer",
          caption: t("customer-setup-fees-list.columns.storage-base"),
          width: "140px",
        },
        {
          name: "totalamount",
          caption: onlyShowFullPrice ? t("customer-setup-fees-list.columns.price") : t("customer-setup-fees-list.columns.price-discounted"),
          width: "140px",
          alignment: "right",
          dataFormat: "number", // For correct sorting
          format: ({ value, row }) => <span title={ t("customer-setup-fees-list.price-full-title-text") + " " + localeFormat(row.data.arades_einzelpreis, { minimumFractionDigits: 2, currency: "EUR" })}>{ localeFormat(value, { minimumFractionDigits: 2, currency: "EUR" }) }</span>,
        },
      ]).concat(onlyShowFullPrice ? [] : [
        {
          name: "promoPercent",
          caption: t("customer-setup-fees-list.columns.discount"),
          width: "140px",
          alignment: "right",
          dataFormat: "number", // For correct sorting
          format: ({ value }) => value !== undefined ? (localeFormat(value) + " %") : "",
        },
        {
          name: "promoName",
          caption: t("customer-setup-fees-list.columns.promo-name"),
          format: ({ value, row }) => <span title={row.data.promoCode}>{value}</span>,
        },
      ]).concat([
        {
          name: "createdon",
          caption: t("customer-setup-fees-list.columns.date"),
          dataFormat: "date", // For correct sorting
          width: "140px",
          format: ({ value }) => dateString(value),
        },
        {
          name: "arades_datum_geldeingang",
          caption: t("customer-setup-fees-list.columns.payment-date"),
          dataFormat: "date", // For correct sorting
          width: "140px",
          format: ({ value, row }) => {
            return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2px" }}>
              {row.data.arades_angebot_annahme?.toString() === QUOTE_STATUS.DONE ? <CheckIcon /> : <ClockIcon />}
              {value ? dateString(value) : t("customer-setup-fees-list.pending")}
            </div>;
          },
        },
    ]));
  }, [onlyShowFullPrice]);

  useEffect(() => {
    // Load the customer setup fee list
    dispatch(resetAll());
    console.log("run");
    dispatch(getCustomerSetupFeeListAction({
      vermittlerId: localStorage.getItem(WORKSPACE_ID)
    }));
    return () => {
      // Reset the action status and error for the next time the component is loaded
      dispatch(resetActionStatus());
      dispatch(resetError());
    };
  }, []);

  // Show error message if there is an error
  useEffect(() => {
    if (customerSetupFeeListGlobalData.error && customerSetupFeeListGlobalData.actionType === "getSetupFeeList") {
      enqueueSnackbar(
        customerSetupFeeListGlobalData.error + ERROR_CONTACT_US,
        { variant: "error", autoHideDuration: 20000, }
      );
      dispatch(resetError());
    }
  }, [ customerSetupFeeListGlobalData.error, customerSetupFeeListGlobalData.actionType, dispatch, enqueueSnackbar ]);

  return (
    <LoadingOverlay show={customerSetupFeeListGlobalData.loading}>
      <div className="container-card">
        <div style={isDesktop ? { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" } : {}}>
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
            {t("customer-setup-fees-list.title")}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox 
                checked={onlyShowFullPrice}
                onChange={(e) => setOnlyShowFullPrice(e.target.checked)}
              />
            }
            label={t("customer-setup-fees-list.show-only-full-price", { count: setupFeeListFullPrice.length })}
            sx={{ marginBottom: 2 }}
          />
        </div>
          {onlyShowFullPrice ? // Switch between tables like this instead of changing the data because the switch animation looks cleaner this way
          <DataTable
            hideTitle={true}
            entityName={"msdyn_warehouse"}
            canAdd={false}
            canEdit={false}
            data={setupFeeListFullPrice ?? []}
            showPageSizeSelector={true}
            columns={dataTableColumns}
          /> :
          <DataTable
            hideTitle={true}
            entityName={"msdyn_warehouse"}
            canAdd={false}
            canEdit={false}
            data={customerSetupFeeListData ?? []}
            showPageSizeSelector={true}
            columns={dataTableColumns}
          />
        }
      </div>
    </LoadingOverlay>
  );
};

export default CustomerSetupFeeList;
