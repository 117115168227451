import { Box, Typography, Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  InputSupportCopy,
  FormStepperStructure,
  LoadingOverlay,
} from "../../../common";
import {
  StorageCreatedStep,
  storageFullData,
  resetActionStatus,
  resetError,
} from "../../../../redux/slices/storage/storage.slice";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL, ERROR_CONTACT_US } from "../../../../core/constants";
import { useTranslation } from "react-i18next";

function StorageCreated({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const storageAllData = useSelector(storageFullData);
  const createdNumbers = useSelector(StorageCreatedStep);
  const [loadingCreate, setLoadingCreate] = useState(true);

  const [values, setValues] = useState({
    goldStorage: createdNumbers?.storage1Number,
    silverStorage: createdNumbers?.storage2Number,
    platiniumStorage: createdNumbers?.storage3Number,
    palladiumStorage: createdNumbers?.storage4Number,
  });
  useEffect(() => {
    if (storageAllData?.globalData?.actionStatus && !storageAllData.error) {
      setLoadingCreate(false);
      dispatch(resetActionStatus);
    } else if (storageAllData.error != null) {
      setLoadingCreate(false);
      enqueueSnackbar(storageAllData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [
    storageAllData?.loading,
    storageAllData?.globalData?.actionStatus,
    storageAllData.error,
  ]);
  const onSubmit = () => {
    //if (signContractData.signDone) handleCurrentStep("next");
    //else
    navigate(URL.STORAGE);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "608px",
          margin: "0 auto",
        }}
      >
        <LoadingOverlay show={loadingCreate}>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "24px",
                fontWeight: "400",
              }}
            >
              {t("add-storage.success.title")}
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {t("add-storage.success.storages-reserved")}
            </Typography>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {storageFullData.wait14Days ? t("add-storage.success.message-after-14-days") : t("add-storage.success.message-before-14-days")}
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography>
              {t("add-storage.success.your-storage-numbers-are")}
            </Typography>
          </Box>

          <Box sx={{
            "& input.Mui-disabled": {
              WebkitTextFillColor: "#000",
            },
          }}>
            <Grid container spacing={2}>
              {[
                { label: t("metals.AU"), value: values.goldStorage },
                { label: t("metals.AG"), value: values.silverStorage },
                { label: t("metals.PT"), value: values.platiniumStorage },
                { label: t("metals.PL"), value: values.palladiumStorage },
              ].map((item, index) => (
                <Grid container item key={index} spacing={2}>
                  <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    {item.label}:
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <InputSupportCopy
                      disabled
                      sx={{ bgcolor: "rgba(0, 0, 0, 0.04)" }}
                      value={item.value}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </LoadingOverlay>

        <FormStepperStructure.Footer>
          <Button
            onClick={onSubmit}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("add-storage.success.go-to-storage")}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </Box>
  );
}

export default StorageCreated;
