import { createSlice } from "@reduxjs/toolkit";
import { getCustomerSetupFeeListAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  setupFeeList: [],
};
export const customerSetupFeeListSlice = createSlice({
  name: "customerSetupFeeList",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getCustomerSetupFeeListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerSetupFeeListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.setupFeeList = action.payload;
      state.globalData.actionType = "getSetupFeeList";
    },
    [getCustomerSetupFeeListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getSetupFeeList";
      state.globalData.error = action.error.message;
    }
  },
});

export const { resetError, resetActionStatus, resetAll } = customerSetupFeeListSlice.actions;
export default customerSetupFeeListSlice.reducer;
