import React, { useMemo } from "react";
import { localeFormat } from "../../../core/utils/convert";
import { Pie } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  tableCellClasses,
} from "@mui/material";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";

// Register the datalabels plugin
Chart.register(ChartDataLabels);

// Display a chart for aggregated precious metal stock
// Example for prices parameter:
// {
//   "AU": {
//     "abbreviation": "AU",
//     "name": "Gold 999/1000",
//     "amount": 18,
//     "price": 1090.9044000000001
//   },
//   "AG": ...
//   "PT": ...
//   "PL": ...
// }
function PreciousMetalsChart({ prices }) {
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const metals = ["AU", "AG", "PT", "PL"];

  // Calculate the total sum of all price values
  const totalValue = useMemo(() => metals.reduce((sum, metal) => sum + (prices?.[metal]?.price ?? 0), 0), [prices, metals]);

  // Prepare data for the Pie chart
  const totalMetalPricesChartData = useMemo(() => ({
    labels: metals, // Use metal abbreviations as labels
    datasets: [
      {
        label: "",
        data: metals.map((metal) => prices?.[metal]?.price ?? 0),
        backgroundColor: [
          "rgba(238, 204, 36, 1)", // Gold
          "rgba(204, 204, 204, 1)", // Silver
          "rgba(227, 242, 253, 1)", // Platinum
          "rgba(252, 217, 185, 1)", // Palladium
        ],
        borderColor: ["rgba(255, 255, 255, 1)"],
        borderWidth: 1,
      },
    ],
  }), [prices, metals]);

  // Configure options for the Pie chart
  const totalMetalPricesChartOptions = useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const metal = context.label;
            return ` ${metal}: ${localeFormat(context.parsed, {
              minimumFractionDigits: 2,
              currency: "EUR",
            })}`;
          },
        },
      },
      datalabels: {
        formatter: function (value, context) {
          if (!Object.keys(prices).length) { return ""; } // No labels while loading because it looks better
          let percentage = value / context.chart.data.datasets[0]?.data.reduce((a, b) => a + b, 0) * 100; // Calculate percentage to hide label if the slice is too thin
          if (isNaN(percentage)) { return ""; } // Hide label if no data is loaded yet because it looks bad
          if (percentage < 5) { return ""; } // Hide label if the slice is too thin because it looks bad
          return context.chart.data.labels[context.dataIndex]; // Display metal abbreviation on the pie slice
        },
        color: function (context) {
          // Use the darkened version of the background color for the label color
          const label = context.chart.data.labels[context.dataIndex];
          if (label === "AU") return "#6F5E06";
          if (label === "AG") return "#545454";
          if (label === "PT") return "#08578F";
          if (label === "PL") return "#6A3402";
          return "black";
        },
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
  }), [prices]);

  return (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      {/* Container for the pie chart (only display if there are values) */}
      {totalValue > 0 && (
        <Grid item xs={12} md={6} style={{ maxWidth: "200px" }}>
          <Pie data={totalMetalPricesChartData} options={totalMetalPricesChartOptions} />
        </Grid>
      )}
      {/* Table with detailed values for the metals */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          minWidth: "fit-content" // Prevent unnecessary horizontal scroll
        }}
      >
        <TableContainer
          style={{
            border: "none",
            minWidth: "fit-content"
          }}
        >
          <Table
            size="small"
            aria-label="precious metals table"
            style={{ borderCollapse: "collapse" }}
            sx={{ [`& .${tableCellClasses.root}`]: { border: "none" } }}
          >
            <TableBody>
              {metals.map((metal) => (
                <TableRow key={metal}>
                  <TableCell style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <b style={{ fontFamily: "sans-serif" }}>{metal}</b>
                    {isDesktop && (
                      <>
                        &nbsp;{t(`metals.${metal}`)}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ paddingLeft: "4px", paddingRight: "4px" }}
                    align="right"
                  >
                    {prices?.[metal]?.amount != null
                      ? localeFormat(prices[metal].amount, {
                          minimumFractionDigits: 4,
                        })
                      : "…"}{" "}
                    g
                  </TableCell>
                  <TableCell
                    style={{ paddingLeft: "4px", paddingRight: "4px" }}
                    align="right"
                  >
                    {prices?.[metal]?.price != null
                      ? localeFormat(prices[metal].price, {
                          minimumFractionDigits: 2,
                          currency: "EUR",
                        })
                      : "…"}
                  </TableCell>
                </TableRow>
              ))}
              {/* Sum line + total stock value */}
              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{
                    borderTop: "2px solid #dddddd",
                    fontWeight: "bold",
                    fontFamily: "sans-serif", /* Enable bold text */
                    paddingLeft: "4px", paddingRight: "4px",
                  }}
                >
                  {t("customer-dashboard.invest-total")}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    borderTop: "2px solid #dddddd",
                    fontWeight: "bold",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                  }}
                >
                  {localeFormat(totalValue, {
                    minimumFractionDigits: 2,
                    currency: "EUR",
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default PreciousMetalsChart;
