import React, { useState, useEffect } from "react";
import SubmitModal from "../common/popups/submitModal";
import { Input } from "../common";
import { ERROR_CONTACT_US } from "../../core/constants";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { postCustomerActionCodeByNameAction } from "../../redux/slices/clientDashboard";
import {
  globalData,
  resetError,
  resetActionStatus,
} from "../../redux/slices/clientDashboard/clientDashboard.slice";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AssignCustomerActionCodeModal = ({
  open,
  onClose,
  customerName,
  contactId,
  accountId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const customerDashbordGlobalData = useSelector(globalData);

  const [enteredActionCode, setEnteredActionCode] = useState();

  const onSubmit = () => {
    dispatch(resetActionStatus());
    dispatch(
      postCustomerActionCodeByNameAction({
        actionCode: enteredActionCode,
        accountId: accountId,
        contactId: contactId,
      })
    );
  };

  useEffect(() => {
    if (
      customerDashbordGlobalData.actionStatus &&
      customerDashbordGlobalData.actionType === "postCustomerActionCode"
    ) {
      enqueueSnackbar(t("messages.success"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
      reset();
      setEnteredActionCode(null);
      dispatch(resetActionStatus());
    }
  }, [customerDashbordGlobalData.actionStatus]);

  useEffect(() => {
    if (customerDashbordGlobalData.error !== null) {
      enqueueSnackbar(customerDashbordGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [customerDashbordGlobalData.error]);

  useEffect(() => {
    setLoading(customerDashbordGlobalData.loading);
  }, [customerDashbordGlobalData.loading]);

  const schema = yup.object().shape({});
  const {
    handleSubmit,
    reset
  } = useForm({
    mode: "all",
    defaultValues: {
      firstname: "",
      lastname: "",
      arades_titel: null,
      birthdate: null,
      actionCode: "",
      emailaddress1: "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <SubmitModal
      open={open}
      title={t("action-code.assign")}
      acceptTitle={t("buttons.add")}
      rejectTitle={t("buttons.cancel")}
      size="lg"
      formIsValid={enteredActionCode}
      onAccept={handleSubmit(onSubmit)}
      loading={loading}
      onReject={() => {
        reset();
        setEnteredActionCode(null);
        onClose();
      }}
      content={
        <Grid
          container
          spacing={6}
          sm={12}
          mt={"10px"}
          sx={{ width: "100%" }}
        >
          <Grid sm={12} item sx={{ width: "100%" }}>
            <Typography
              as="div"
              sm={12}
              sx={{
                whiteSpace: "pre",
                display: "flex",
                width: "100%",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {customerName}
            </Typography>
          </Grid>
          <Grid sm={12} item sx={{ width: "100%" }} mt={10}>
            <Input
              name={"enteredActionCode"}
              value={enteredActionCode}
              onChange={(e) => setEnteredActionCode(e.target.value)}
              label={t("action-code.enter") + "*"}
            ></Input>
          </Grid>
        </Grid>
      }
    ></SubmitModal>
  );
};

export default AssignCustomerActionCodeModal;
